import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabsTwo extends Component {
  render() {
    let tab1 = 'Main skills',
      tab2 = 'Experience',
      tab3 = 'Education & Certification';
    const { tabStyle } = this.props;
    return (
      <div>
        {/* Start Tabs Area */}
        <div className='tabs-area'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <Tabs>
                  <TabList className={`${tabStyle}`}>
                    <Tab>{tab1}</Tab>
                    <Tab>{tab2}</Tab>
                    <Tab>{tab3}</Tab>
                  </TabList>

                  <TabPanel>
                    <div className='single-tab-content'>
                      <ul>
                        <li>
                          <a href='/service'>UI Development</a>
                          The development of websites, web applications,software
                          development
                        </li>
                        <li>
                          <a href='/service'>Node.js Development</a>
                          deploy and maintain network applications.
                        </li>
                        <li>
                          <a href='/service'>MERN Stack Development</a>
                          MongoDB - document database <br />
                          Express(.js) - Node.js web framework <br />
                          React(.js) - a client-side JavaScript framework <br />
                          Node(.js) - the premier JavaScript web server
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className='single-tab-content'>
                      <ul>
                        <li>
                          <a href='/service'>
                            MERN Stack Developer<span> - HashCrafts </span>
                          </a>{' '}
                          2020 - Current
                        </li>
                        <li>
                          <a href='/service'>
                            Front-end Engineer<span> - Vvork Clouds</span>
                          </a>{' '}
                          2018 - 2020
                        </li>
                        <li>
                          <a href='/service'>
                            JS Instructor<span> - Vvork Clouds</span>
                          </a>{' '}
                          2017 - 2018
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className='single-tab-content'>
                      <ul>
                        <li>
                          <a href='/service'>
                            BS(Honors) In Computer Science
                            <span>
                              {' '}
                              - University of Gujrat, Punjab, Pakistan
                            </span>
                          </a>{' '}
                          2021
                        </li>
                        <li>
                          <a href='/service'>
                            Diploma in MERN Stack
                            <span> - Vvork Cloud Technologies</span>
                          </a>{' '}
                          2020
                        </li>
                        <li>
                          <a href='https://www.youracclaim.com/badges/30f6dfe8-c6b3-4034-ba5f-106cf6ff7b8b/linked_in_profile'>
                            Exam 480: Programming in HTML5 with JavaScript and
                            CSS3
                            <span> - Microsoft</span>
                          </a>{' '}
                          2019
                        </li>
                      </ul>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        {/* End Tabs Area */}
      </div>
    );
  }
}

export default TabsTwo;
