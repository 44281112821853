import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import Helmet from '../component/common/Helmet';
import TextLoop from 'react-text-loop';
import HeaderThree from '../component/header/HeaderThree';
import FooterTwo from '../component/footer/FooterTwo';
import TabTwo from '../elements/tab/TabTwo';
import ContactThree from '../elements/contact/ContactThree';
import PortfolioList from '../elements/portfolio/PortfolioList';
import ServiceList from '../elements/service/ServiceList';
import BlogContent from '../elements/blog/BlogContent';

const SlideList = [
  {
    textPosition: 'text-left',
    category: 'Welcome to my World',
    description: '',
    buttonText: '',
    buttonLink: '',
  },
];
const PortfolioLanding = () => {
  let title = 'About Me',
    description =
      'Dynamic software developer with over 2 years of experience in producing clean and strong code. Eager to support and develop the project with team as well as individually with top-notch coding skills.Previously had experienced to teach skills to pupils and built the creative responsive websites as students team manager.';
  const PostList = BlogContent.slice(0, 3);
  return (
    <div className='active-dark'>
      <Helmet pageTitle='Ahmed Raza' />

      <HeaderThree homeLink='/' logo='symbol-dark' color='color-black' />
      {/* Start Slider Area   */}
      <div id='home' className='fix'>
        <div className='slider-wrapper'>
          {/* Start Single Slide */}
          {SlideList.map((value, index) => (
            <div
              className='slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--25'
              key={index}
            >
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-12'>
                    <div className={`inner ${value.textPosition}`}>
                      {value.category ? <span>{value.category}</span> : ''}
                      <h1 className='title'>
                        Hi, I’m Ahmed Raza
                        <br />
                        <TextLoop>
                          <span> JS Developer.</span>
                          <span> Front-End Developer</span>
                          <span>MERN Stack Expert</span>
                        </TextLoop>{' '}
                      </h1>
                      <h2>based in Pakistan</h2>
                      {value.description ? (
                        <p className='description'>{value.description}</p>
                      ) : (
                        ''
                      )}
                      {value.buttonText ? (
                        <div className='slide-btn'>
                          <a
                            className='rn-button-style--2 btn-primary-color'
                            href={`${value.buttonLink}`}
                          >
                            {value.buttonText}
                          </a>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* End Single Slide */}
        </div>
      </div>
      {/* End Slider Area   */}

      {/* Start About Area */}
      <div id='about' className='fix'>
        <div className='about-area ptb--120  bg_color--1'>
          <div className='about-wrapper'>
            <div className='container'>
              <div className='row row--35 align-items-center'>
                <div className='col-lg-5'>
                  <div className='thumbnail'>
                    <img
                      className='w-100'
                      src='/assets/images/new-profile.jpg'
                      alt='About Images'
                    />
                  </div>
                </div>
                <div className='col-lg-7'>
                  <div className='about-inner inner'>
                    <div className='section-title'>
                      <h2 className='title'>{title}</h2>
                      <p className='description'>{description}</p>
                    </div>
                    <div className='row mt--30'>
                      <TabTwo tabStyle='tab-style--1' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* End About Area */}

      {/* Start Service Area  */}
      <div id='service' className='fix'>
        <div className='service-area creative-service-wrapper ptb--120 bg_color--5'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='section-title text-center service-style--3 mb--30 mb_sm--0'>
                  <h2 className='title'>My Awesome Service</h2>
                  <p>
                    My MERN Stack Services have always amazed my clients by
                    meeting their business requirements as well as provide
                    applauds from the industry leaders.
                  </p>
                </div>
              </div>
            </div>
            <div className='row creative-service'>
              <div className='col-lg-12'>
                <ServiceList
                  item='6'
                  column='col-lg-4 col-md-6 col-sm-6 col-12 text-left'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Service Area  */}

      {/* Start Portfolio Area */}
      <div id='portfolio' className='fix'>
        <div className='portfolio-area ptb--120 bg_color--1'>
          <div className='portfolio-sacousel-inner'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='section-title text-center service-style--3 mb--30 mb_sm--0'>
                    <h2 className='title'>My Latest Project</h2>
                    <p>
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration.
                    </p>
                  </div>
                </div>
              </div>
              <div className='row'>
                <PortfolioList
                  styevariation='text-center mt--40'
                  column='col-lg-4 col-md-6 col-sm-6 col-12'
                  item='6'
                />
              </div>
              {/* <div className='row'>
                <div className='col-lg-12'>
                  <div className='view-more-btn mt--60 mt_sm--30 text-center'>
                    <a className='rn-button-style--2 btn-solid' href='/blog'>
                      <span>View More</span>
                    </a>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* End Portfolio Area */}

      {/* Start Blog Area */}
      {/* <div id='blog' className='fix'>
        <div className='rn-blog-area ptb--120 bg_color--5 mb-dec--30'>
          <div className='container'>
            <div className='row align-items-end'>
              <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                <div className='section-title text-center'>
                  <h2>Latest News</h2>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, <br />
                    but the majority have suffered alteration.
                  </p>
                </div>
              </div>
            </div>
            <div className='row mt--60 mt_sm--40'>
              {PostList.map((value, i) => (
                <div className='col-lg-4 col-md-6 col-12' key={i}>
                  <div className='blog blog-style--1'>
                    <div className='thumbnail'>
                      <a href='/blog-details'>
                        <img
                          className='w-100'
                          src={`/assets/images/blog/blog-${value.images}.jpg`}
                          alt='Blog Images'
                        />
                      </a>
                    </div>
                    <div className='content'>
                      <p className='blogtype'>{value.category}</p>
                      <h4 className='title'>
                        <a href='/blog-details'>{value.title}</a>
                      </h4>
                      <div className='blog-btn'>
                        <a className='rn-btn text-white' href='/blog-details'>
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div> */}
      {/* End Blog Area */}

      {/* Start COntact Area */}
      <div id='contact' className='fix'>
        <div className='rn-contact-area ptb--120 bg_color--1'>
          <ContactThree
            contactImages='/assets/images/newdata.png'
            contactTitle='Hire Me.'
          />
        </div>
      </div>
      {/* End COntact Area */}

      <FooterTwo />
      {/* Start Back To Top */}
      <div className='backto-top'>
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </div>
  );
};

export default PortfolioLanding;
