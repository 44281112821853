import React from 'react';
import { FaLinkedin, FaGitSquare } from 'react-icons/fa';

const SocialShare = [
  {
    Social: <FaLinkedin />,
    link: 'https://www.linkedin.com/in/ahmad-raza-3ab825160/',
  },
  { Social: <FaGitSquare />, link: 'https://github.com/Ahmed3179' },
];

const FooterTwo = () => {
  return (
    <div
      className='footer-style-2 ptb--30 bg_image bg_image--1'
      style={{ backgroundColor: '#0099CC' }}
    >
      <div className='wrapper plr--50 plr_sm--20'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <div className='inner'>
              <div className='logo text-center text-sm-left mb_sm--20'>
                <a href='/home-one'>
                  <img
                    src='/assets/images/logo/logo-design.png'
                    alt='Logo images'
                  />
                </a>
              </div>
            </div>
          </div>
          <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
            <div className='inner text-center'>
              <ul className='social-share rn-lg-size d-flex justify-content-center liststyle'>
                {SocialShare.map((val, i) => (
                  <li key={i}>
                    <a href={`${val.link}`}>{val.Social}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className='col-lg-4 col-md-12 col-sm-12 col-12'>
            <div className='inner text-lg-right text-center mt_md--20 mt_sm--20'>
              <div className='text'>
                <p>Copyright © 2021 AR. All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FooterTwo;
